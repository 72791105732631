var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":"","cols":"3"}},[_c('v-col',[_c('v-select',{staticClass:"pa-2",attrs:{"outlined":"","filled":"","dense":"","label":"Product","value":_vm.product,"items":_vm.products,"item-text":"text","item-value":"value"},on:{"input":function (dateRange) { return _vm.reOrderData(); }},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('v-col',[_c('v-select',{staticClass:"pa-2",attrs:{"outlined":"","filled":"","dense":"","label":"Magazine","value":_vm.magazine,"items":_vm.magazines,"item-text":"text","item-value":"value"},on:{"input":function (dateRange) { return _vm.reOrderData(); }},model:{value:(_vm.magazine),callback:function ($$v) {_vm.magazine=$$v},expression:"magazine"}})],1),_c('v-col'),_c('v-col'),_c('v-col')],1),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Inventory"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-simple-table',{attrs:{"fixed-header":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":20},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('v-row',{staticStyle:{"width":"300px"}},[_c('v-col',[_c('h4',[_vm._v("Total Buy: "+_vm._s(_vm.buySum)+" Lek")])]),_c('v-col',[_c('h4',[_vm._v("Total Sell: "+_vm._s(_vm.sellSum)+" Lek")])])],1)]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.amount)+" "+_vm._s(item.measurement_name))])]}},{key:"item.buy_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.buy_price)+" "+_vm._s(_vm.currency)+" ")]}},{key:"item.sell_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sell_price)+" "+_vm._s(_vm.currency)+" ")]}}])})],1)],1),_c('div',{staticClass:"text-center"},[_c('downloadExcel',{staticClass:"elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile",attrs:{"data":_vm.items,"fields":{
        id: 'id',
        product_name: 'product_name',
        product_sku: 'product_sku',
        amount: 'amount',
        magazine_name: 'magazine_name',
        product_buy_price: 'buy_price',
        product_sell_price: 'sell_price',
      }}},[_vm._v("   Export Excel    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }