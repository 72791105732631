<template>
  <div>
    <v-row no-gutters cols="3">
      <v-col>
        <v-select
          class="pa-2"
          outlined
          filled
          dense
          label="Product"
          :value="product"
          v-model="product"
          :items="products"
          item-text="text"
          item-value="value"
          @input="(dateRange) => reOrderData()"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          class="pa-2"
          outlined
          filled
          dense
          label="Magazine"
          :value="magazine"
          v-model="magazine"
          :items="magazines"
          item-text="text"
          item-value="value"
          @input="(dateRange) => reOrderData()"
        ></v-select>
      </v-col>

      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>

    <v-card>
      <v-card-title>
        {{ translate("Inventory") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-simple-table fixed-header>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="filteredItems"
          :items-per-page="20"
          class="elevation-1"
        >
          <template v-slot:footer.page-text>
            <v-row style="width: 300px">
              <v-col>
                <h4>Total Buy: {{ buySum }} Lek</h4>
              </v-col>
              <v-col>
                <h4>Total Sell: {{ sellSum }} Lek</h4>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.amount="{ item }">
            <v-chip color="success">{{ item.amount }} {{ item.measurement_name }}</v-chip>
          </template>
          <template v-slot:item.buy_price="{ item }">
            {{ item.buy_price }}
            {{ currency }}
          </template>
          <template v-slot:item.sell_price="{ item }">
            {{ item.sell_price }}
            {{ currency }}
          </template>
        </v-data-table>
      </v-simple-table>
    </v-card>
    <div class="text-center">
      <downloadExcel
        class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile"
        :data="items"
        :fields="{
          id: 'id',
          product_name: 'product_name',
          product_sku: 'product_sku',
          amount: 'amount',
          magazine_name: 'magazine_name',
          product_buy_price: 'buy_price',
          product_sell_price: 'sell_price',
        }"
      >
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>
<script>
import { getCurrency, getStocks } from "@/ApiManager";
import axios from "axios";

export default {
  data() {
    return {
      magazine: null,
      product: null,
      search: "",
      currency: "",
      headers: [
        { text: "ID", value: "id" },
        { text: this.translate("Product"), value: "product_name" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Amount"), value: "amount" },
        {
          text: this.translate("Buy Price"),
          value: "buy_price",
        },
        {
          text: this.translate("Sell Price"),
          value: "sell_price",
        },
      ],
      items: [],
      filteredItems: [],
    };
  },
  methods: {
    reOrderData() {
      var response = [];
      for (var i = 0; i < this.items.length; i++) {
        if (this.product != null) {
          if (this.product != this.items[i].product_id) continue;
        }
        if (this.magazine != null) {
          if (this.magazine != this.items[i].magazine_id) continue;
        }
        response.push(this.items[i]);
      }
      this.filteredItems = response;
    },
  },
  computed: {
    buySum() {
      var res = 0;
      for (var i = 0; i < this.items.length; i++) {
        res += this.items[i].buy_price * this.items[i]["amount"];
      }
      return res;
    },
    sellSum() {
      var res = 0;
      for (var i = 0; i < this.items.length; i++) {
        res += this.items[i].sell_price * this.items[i]["amount"];
      }
      return res;
    },
    products() {
      var result = [];
      result.push({ text: "All", value: null });
      for (var i = 0; i < this.items.length; i++) {
        result.push({
          text: this.items[i].product_name,
          value: this.items[i].product_id,
        });
      }
      return result;
    },
    magazines() {
      var result = [];
      result.push({ text: "All", value: null });
      for (var i = 0; i < this.items.length; i++) {
        result.push({
          text: this.items[i].magazine_name,
          value: this.items[i].magazine_id,
        });
      }
      return result;
    },
  },
  created() {
    this.$store.state.loading = true;
    getStocks().then((response) => {
      this.items = response;
      this.filteredItems = response;
      this.$store.state.loading = false;
    });
    getCurrency().then((response) => {
      this.currency = response.currency;
    });
  },
};
</script>
